.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.claim-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-left: 25%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
header {
  background-color:#ffffff; /* Background color of the header */
  padding: 5px 0; /* Spacing inside the header */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff; /* Text color */
  font-family: 'Poppins', sans-serif;
  font-size: larger;
  font-weight: 400;
}

.logo img {
  width: 158px; 
  height: 122px;
}

nav ul {
  list-style-type: none; /* Remove bullet points from the list */
  padding: 0;
  display: flex;
}

nav li {
  margin-right: 10px;

  /* Spacing between navigation links */
}

h2 {
   color: rgb(35, 132, 196);
}

h3 {
  color: rgb(35, 132, 196);
}
nav a {
  box-shadow: inset 0 0 0 0 #rgb(35, 132, 196);
  text-decoration: none;
  color: black;
  margin: 0 -.25rem;
  padding: 0 .25rem;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

nav a:hover {
  box-shadow: inset 100px 0 0 0 #54b3d6;
  color: white;
  /* Change text color on hover (you can choose any color you like) */
}

.ag-theme-quartz {
  --ag-header-height: 30px;
  --ag-header-foreground-color: white;
  --ag-header-background-color: rgb(35, 132, 196);
  --ag-header-cell-hover-background-color: rgb(35, 132, 196);
  --ag-header-cell-moving-background-color: rgb(80, 40, 140);
  --ag-border-radius: 10px;
}
.ag-theme-quartz .ag-header {
  font-family: Arial;
}
.ag-theme-quartz .ag-header-group-cell {
  font-weight: normal;
  font-size: 22px;
}
.ag-theme-quartz .ag-header-cell {
  font-size: 18px;
}